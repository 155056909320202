import useJwt from '@/auth/jwt/useJwt'
import { formatDateViewToDb } from '@/utils/helpers'

export default class InformativeService {
  // eslint-disable-next-line class-methods-use-this
  list(filters, page = 0) {
    let params = {}
    if (filters !== undefined) {
      params = Object.fromEntries(Object.entries(filters).filter(filter => filter[1] !== '' && filter[1] !== 'all'))
    }

    params.order_by = params.sortBy + (params.sortDesc ? ' DESC' : '')
    delete params.sortDesc
    delete params.sortBy

    return useJwt.get(`/informatives?page=${page}`, params)
  }

  // eslint-disable-next-line class-methods-use-this
  html(id) {
    return useJwt.get(`/informative/html/${id}`)
  }

  // eslint-disable-next-line class-methods-use-this
  delete(id) {
    return useJwt.delete(`/informative/${id}`)
  }

  // eslint-disable-next-line class-methods-use-this
  post(data) {
    const newData = {
      ...data,
      users: data.users.split('\n'),
      send_in: formatDateViewToDb(data.send_in),
    }

    return useJwt.post('/informative', {}, newData)
  }

  // eslint-disable-next-line class-methods-use-this
  update(id, data) {
    const newData = {
      ...data,
      users: data.users.split('\n'),
      send_in: formatDateViewToDb(data.send_in),
    }

    return useJwt.post(`/informative/${id}`, {}, newData)
  }

  // eslint-disable-next-line class-methods-use-this
  get(id) {
    return useJwt.get(`/informative/${id}`)
  }

  // eslint-disable-next-line class-methods-use-this
  login() {
    return 'url'
  }
}
