<template>
  <section id="conversation">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <b-row>
            <b-col cols="3">
              <b-form-group
                label="Tipo"
                label-for="filter_type"
              >
                <b-form-select
                  id="filter_type"
                  v-model="filtersValues.type"
                  :options="filtersOptions.type"
                />
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Status"
                label-for="filter_status"
              >
                <b-form-select
                  id="filter_status"
                  v-model="filtersValues.status"
                  :options="filtersOptions.status"
                />
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Nome / Referência"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="filtersValues.name"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="3"
              class="form-buttons"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  id="btn-buscar"
                  variant="primary"
                  @click="getInformatives()"
                >
                  Buscar
                </b-button>

                <b-button
                  id="btn-limpar"
                  variant="outline-secondary"
                  @click="reset()"
                >
                  Limpar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card>
          <b-row
            class="header-card"
            align-v="center"
          >
            <b-col>
              <h4>
                Resultados
              </h4>
            </b-col>

            <b-col class="text-right">
              <b-button
                variant="primary"
                :to="{ name: 'cms-informative-form-insert' }"
              >
                <span class="text-nowrap">Adicionar Informativo</span>
              </b-button>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-table
                :busy="isLoading"
                responsive
                :items="results"
                :fields="result_fields"
                sort-icon-left
                no-local-sorting
                :sort-by.sync="filtersValues.sortBy"
                :sort-desc.sync="filtersValues.sortDesc"
                @sort-changed="sortingChanged"
              >
                <template #table-busy>
                  <div class="text-center table-spinner">
                    <b-spinner label="Loading..." />
                    <strong>Carregando...</strong>
                  </div>
                </template>

                <template #head(status)>
                  <div class="text-nowrap">
                    Status
                  </div>
                </template>

                <template #head(type)>
                  <div class="text-nowrap">
                    Tipo
                  </div>
                </template>

                <template #head(name)>
                  <div class="text-nowrap">
                    Nome
                  </div>
                </template>

                <template #head(reference)>
                  <div class="text-nowrap">
                    Referência
                  </div>
                </template>

                <template #head(slug)>
                  <div class="text-nowrap">
                    Slug
                  </div>
                </template>

                <template #head(send_in)>
                  <div class="text-nowrap">
                    Data de envio
                  </div>
                </template>

                <template #head(actions)>
                  <div class="text-nowrap text-center">
                    Ações
                  </div>
                </template>

                <template #cell(send_in)="row">
                  {{ formatDateDbToView(row.item.send_in) }}
                </template>

                <template #cell(actions)="row">
                  <div class="text-nowrap text-center">
                    <b-button
                      v-if="$can('update', 'Informative')"
                      variant="flat-primary"
                      :to="{ name: 'cms-informative-form-update', params: { id: row.item.id } }"
                      title="Editar"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>

                    <b-button
                      v-if="$can('delete', 'Informative')"
                      variant="flat-primary"
                      title="Deletar"
                      @click="deleteInformative(row.item.id)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>

                    <b-button
                      v-if="$can('read', 'Informative')"
                      variant="flat-primary"
                      :to="{ name: 'cms-informative-html', params: { id: row.item.id } }"
                      title="Ver html"
                    >
                      <feather-icon
                        icon="FileTextIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>
                  </div>
                </template>

                <template #row-details="row">
                  <b-spinner v-if="!row.item.message" />
                  <p>{{ row.item.message }}</p>
                </template>

              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
            </b-col>

            <b-col cols="6">
              <!-- pagination nav -->
              <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="pages.last_page"
                base-url="#"
                align="end"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>

    </b-row>

    <b-modal
      id="modal-close-message"
      title="Encerrar conversa"
      ok-title="Encerrar conversa"
      cancel-title="voltar"
      cancel-variant="outline-secondary"
      @ok="handleCloseConversation()"
    >
      <h2>Selecione o motivo para encerrar a conversa</h2>
      <b-form-group>
        <v-select
          v-model="closeMessageSelected"
          :options="closeMessageOptions"
          label="title"
        />
      </b-form-group>
    </b-modal>

  </section>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import InformativeService from '@/services/informativeService'
import { formatDateDbToView } from '@/utils/helpers'
// import moment from 'moment'
import {
  BRow,
  BCol,
  BTable,
  BFormInput,
  BFormGroup,
  BFormSelect,
  // BDropdown,
  // BDropdownItem,
  BPaginationNav,
  BSpinner,
  BButton,
  BModal,
  BCard,
  BBreadcrumb,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardActions,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BFormGroup,
    BFormSelect,
    // BDropdown,
    // BDropdownItem,
    BPaginationNav,
    BSpinner,
    BButton,
    BModal,
    BBreadcrumb,
    vSelect,
  },

  data() {
    return {
      isLoading: true,
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Informativos',
          active: true,
        },
      ],
      selectSelected: 'enviadas',
      userId1: '',
      userId2: '',
      informativeService: null,
      modalConversationId: 0,
      closeMessageSelected: {},
      closeMessageOptions: [
        { title: 'Fechamos a parceria', value: 'Fechamos a parceria' },
        { title: 'Foge ao Perfil', value: 'Foge ao Perfil' },
        { title: 'Não tenho interesse', value: 'Não tenho interesse' },
        { title: 'Outro', value: 'Outro' },
        { title: 'Pirâmide', value: 'Pirâmide' },
        { title: 'Propaganda/Venda de Serviços', value: 'Propaganda/Venda de Serviços' },
        { title: 'Sem retorno', value: 'Sem retorno' },
      ],
      selectOptions: [
        { value: 'enviadas', text: 'Enviadas' },
        { value: 'recebidas', text: 'Recebidas' },
        { value: 'conversa', text: 'Conversa' },
      ],

      filtersInitialValues: {},
      filtersValues: {
        name: '',
        status: 'all',
        type: 'all',
        sortBy: 'id',
        sortDesc: true,
      },
      filtersOptions: {
        status: [
          { value: 'all', text: 'Todos' },
          { value: 'pending', text: 'Pendente' },
          { value: 'sent', text: 'Enviado' },
        ],
        type: [
          { value: 'all', text: 'Todos' },
          { value: 'company', text: 'Empresa' },
          { value: 'representative', text: 'Representante' },
        ],
      },

      pages: 1,
      currentPage: 1,
      results: [],
      result_fields: [],
      formatDateDbToView,
    }
  },

  watch: {
    currentPage() {
      this.getInformatives()
    },
  },

  created() {
    this.informativeService = new InformativeService()
    this.getInformatives()
    this.filtersInitialValues = { ...this.filtersValues }

    this.result_fields = [
      { key: 'id', sortable: true },
      { key: 'name', sortable: true },
      { key: 'reference', sortable: true },
      { key: 'slug', sortable: true },
      { key: 'send_in', sortable: true },
      { key: 'status', sortable: true },
      { key: 'type', sortable: true },
    ]

    if (this.showActions()) {
      this.result_fields.push('actions')
    }
  },

  methods: {
    reset() {
      this.filtersValues = JSON.parse(JSON.stringify(this.filtersInitialValues))
      this.getInformatives()
    },

    sortingChanged(ctx) {
      this.filtersValues.sortBy = ctx.sortBy
      this.filtersValues.sortDesc = ctx.sortDesc
      this.getInformatives()
    },

    showActions() {
      return this.$can('read', 'Informative') || this.$can('update', 'Informative') || this.$can('delete', 'Informative')
    },

    deleteInformative(informativeId) {
      this.$swal({
        title: 'Deletar informativo?',
        text: 'Esta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, deletar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.informativeService.delete(informativeId).then(response => {
            if (response.status === 200) {
              this.results = this.results.filter(filter => filter.id !== informativeId)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Informativo ${informativeId} removido!`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  // text: `!`,
                },
              })
            }
          })
        }
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erro!',
            text: error.response.data.message,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      })
    },

    getInformatives() {
      this.isLoading = true
      this.informativeService.list(this.filtersValues, this.currentPage).then(response => {
        if (response.data !== null) {
          const informatives = response.data.data.informatives.data
          this.results = informatives

          this.pages = response.data.data.informatives
          delete this.pages.data
        }
        this.isLoading = false
      })
    },

  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .header-card{
    margin-bottom: 20px;
  }

  .form-buttons button{
    margin-right: 10px;
  }
</style>
